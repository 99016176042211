<template lang="pug">
div
  inline-svg.log-card-icon(:src="require('../../../assets/icons/checklist/log_card_icon.svg')", @click="modal = true")

  q-dialog(v-model="modal")
    q-card.modal-form-card.log-card
      .log-card-title
        span {{ title }}

      table
        tr.log-card-param(v-for="param in data")
          td.label
            span {{ param["label"] }}
          td.value
            span {{ param["value"] }}
</template>

<script>
export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      modal: false,
    };
  },

  computed: {
    title() {
      return this.parentData.title;
    },

    data() {
      return this.parentData.data;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/checklists/log_card";
</style>
