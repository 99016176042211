import showIssue from "../../view/issues/show";
import showContract from "../../view/contracts/show";
import showPprIssue from "../../view/ppr/issues/show";
import showMarketingIssue from "../../view/marketing/issues/show";
import showPprEquipment from "../../view/ppr/equipments/show.vue";
import showOrderType from "../../view/order_types/show.vue";
import showFinancialManagementDepartment from "../../view/financial_management_departments/show";
import showPurchaseGroup from "../../view/purchase_groups/show.vue";
import showLongtermContract from "../../view/longterm_contracts/show.vue";

export default {
  issues: showIssue,
  archive: showIssue,
  ppr_issues: showPprIssue,
  ppr_archive: showPprIssue,
  ppr_equipments: showPprEquipment,
  contracts: showContract,
  marketing_issues: showMarketingIssue,
  marketing_archive: showMarketingIssue,
  order_types: showOrderType,
  financial_management_departments: showFinancialManagementDepartment,
  purchase_groups: showPurchaseGroup,
  longterm_contracts: showLongtermContract,
};
