<template lang="pug">
div
  .additional-tarifiers-form
    q-form(@submit="submitForm", :class="[form_valid ? 'wrapper' : 'wrapper valid-error']")
      .form-card
        .list-item.title-input
          .form-field-label
            span {{ issue_tarifier_locales.additional_title }}
          .form-field.form-input
            q-input(
              filled,
              no-error-icon,
              v-model="form.title",
              :label-slot="false",
              lazy-rules,
              :rules="[val => validTitle || notifies.not_empty]"
            )

        .coeff-checkboxes
          .list-item.checkbox
            //div(class="form-field-label")
              span {{issue_tarifier_locales.additional_work_cramped_work}}
            .form-field.form-checkbox
              q-checkbox(
                dark,
                v-model="form.work.cramped_work",
                :label="issue_tarifier_locales.additional_work_cramped_work"
              )

          .list-item.checkbox
            //div(class="form-field-label")
              span {{issue_tarifier_locales.additional_work_night_work}}
            .form-field.form-checkbox
              q-checkbox(
                dark,
                v-model="form.work.night_work",
                :label="issue_tarifier_locales.additional_work_night_work"
              )

        .additional-fields
          .work-fields
            .list-item.count-input
              .form-field-label
                span {{ issue_tarifier_locales.additional_work_count }}
              .form-field.form-input
                q-input.float-field(
                  filled,
                  no-error-icon,
                  v-model.number="form.work.count",
                  type="number",
                  min="0",
                  step="any",
                  :label-slot="false"
                )

            .list-item.cost-input
              .form-field-label
                span {{ issue_tarifier_locales.additional_work_cost }}
              .form-field.form-input
                q-input.float-field(
                  filled,
                  no-error-icon,
                  v-model.number="form.work.cost",
                  type="number",
                  min="0",
                  step="any",
                  :label-slot="false"
                )

          .material-fields
            .list-item.count-input
              .form-field-label
                span {{ issue_tarifier_locales.additional_material_count }}
              .form-field.form-input
                q-input.float-field(
                  filled,
                  no-error-icon,
                  v-model.number="form.material.count",
                  type="number",
                  min="0",
                  step="any",
                  :label-slot="false"
                )

            .list-item.cost-input
              .form-field-label
                span {{ issue_tarifier_locales.additional_material_cost }}
              .form-field.form-input
                q-input.float-field(
                  filled,
                  no-error-icon,
                  v-model.number="form.material.cost",
                  type="number",
                  min="0",
                  step="any",
                  :label-slot="false"
                )

        .button-group.row.justify-center
          q-btn.additional-submit(
            flat,
            no-caps,
            color="primary",
            :label="issue_tarifier_locales.additional_add_to_list",
            type="submit"
          )

    span.valid-error-message(v-show="valid_error_message") {{ valid_error_message }}
</template>

<script>
export default {
  components: {},

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      form: {
        title: "",

        work: {
          count: 0,
          cost: 0,
          cramped_work: false,
          night_work: false,
        },

        material: {
          count: 0,
          cost: 0,
        },
      },
      form_valid: true,
      valid_error_message: null,
    };
  },

  computed: {
    issue_tarifier_locales() {
      return this.locales.issue_tarifier[this.current_locale];
    },

    validTitle() {
      let title = this.form.title;
      return !["", null, undefined].includes(title);
    },
  },

  created() {},

  methods: {
    checkAdditionalsForm() {
      let val = !this.validTitle;
      this.$emit("set-form-valid", val);
      this.form_valid = val;
      this.valid_error_message = val ? null : this.issue_tarifier_locales.need_add_to_list;
    },

    submitForm() {
      this.$emit("reload-additional", { action: "add", attr: this.form });
      this.resetForm();
      this.checkAdditionalsForm();
    },

    resetForm() {
      this.form = {
        title: "",
        work: {
          count: 0,
          cost: 0,
          cramped_work: false,
          night_work: false,
        },
        material: {
          count: 0,
          cost: 0,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.additional-tarifiers-form {
  .wrapper {
    /*display: flex;*/
    padding: 5px;

    .form-card {
      width: auto;
      /*background: var(--show-card-emergency-block-background);*/
      /*border-radius: 15px;*/
      display: flex;
      flex-wrap: wrap;
    }

    .form-field-label {
      span {
        font-size: 11px !important;
      }
    }

    .list-item {
      padding: 7px;
    }
    .title-input {
      width: 100%;
    }

    .coeff-checkboxes {
      width: 50%;
      /*display: flex;*/
      margin-top: 7px;

      .checkbox {
        /*width: 45%;*/
        padding: 0;

        .q-checkbox__label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
        }

        span.no-outline {
          display: none;
        }

        .form-field-label {
          white-space: normal;
          display: flex;
          height: 30px;

          span {
            line-height: 15px;
          }
        }

        .form-checkbox {
          margin-left: 10px;
        }

        /*.q-checkbox {*/
        /*justify-content: center;*/
        /*}*/
      }
    }

    .additional-fields {
      width: 50%;

      .work-fields,
      .material-fields {
        width: 100%;
        display: flex;

        .count-input {
          width: 50%;
        }
        .cost-input {
          width: 50%;
        }
      }
    }

    .additional-submit {
      border-radius: 8px;
      background-color: #5b7aff;

      span {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 20px;
        color: #fff;
        padding: 0;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @import "../../../../assets/styles/forms/main";
    @import "../../../../assets/styles/forms/fields/string";
    @import "../../../../assets/styles/forms/fields/checkbox";

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      padding: 10px;
    }
  }

  .valid-error-message {
    margin-top: 0px;
  }

  .q-field__bottom {
    padding-top: 7px !important;
    padding-left: 4px !important;
  }
}
</style>
