<template lang="pug">
#form.new-edit-form
  .form-card
    q-card-section
      form-header(
        ref="form-header",
        @close-form="closeForm",
        :parentData="{ grid: grid, title: form_title, title_icon: title_icon_path }"
      )

    .spinner-container.form-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

    q-card-section(v-show="!loading")
      tabs(
        v-if="data.tabs && data.tabs.data.length > 0 && (!data.tabs.method_limit || data.tabs.method_limit !== method)",
        @fields-loaded="fieldsLoaded",
        @submit-form="submitForm",
        :parentData="{ method: method, grid: grid, data: data, item_id: item_id }"
      )

      component(
        v-else,
        :is="getForm('default_form')",
        @fields-loaded="fieldsLoaded",
        @submit-form="submitForm",
        :parentData="{ method: method, grid: grid, data: data, item_id: item_id }"
      )
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import tabs from "./new_edit_form/tabs";
import forms from "./new_edit_form/forms";

export default {
  components: {
    formHeader,
    forms,
    tabs,
  },
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      method: this.parentData.method,
      item: this.parentData.item,
      item_id: this.parentData.item_id || "",
      callback_params: this.parentData.callback_params,
      path: this.parentData.path,
      // data: this.parentData.data,
      grid: this.parentData.grid,
      form_title: this.parentData.form_title,
      title_icon_path: this.parentData.title_icon_path,

      loading_fields: [],
      loading: true,

      form_valid: false,
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.resetForm();
    this.fieldsLoaded();
  },

  methods: {
    getForm(key) {
      if (key) {
        return forms[key];
      }
    },

    closeForm(params = {}) {
      params["method"] = this.method;
      this.$emit("close-form", params);
    },

    submitForm() {
      let params = {};
      let form = this.currentForm;

      this.checkFormValidation(form);

      if (this.form_valid) {
        this.loading = true;

        let result = Object.keys(form).reduce((result, e) => {
          if (Array.isArray(form[e]["field"]) && form[e]["selected_items"]) {
            result[e] = form[e]["selected_items"];
          } else if (Array.isArray(form[e]["field"])) {
            result[e] = form[e]["field"].map(e => e["value"]);
          } else if (typeof form[e]["field"] === "object") {
            result[e] = form[e]["field"]["value"];
          } else {
            result[e] = form[e]["field"];
          }
          return result;
        }, {});

        params[this.item] = result;

        this.$backend[this.method](this.path, this.item_id, params)
          .then(res => {
            const forceRefreshList = res.data.force_refresh_list;
            const data = res.data["data"] || res.data;

            if (data) {
              this.$nextTick(() => {
                const res_params = { data: data, method: this.method, force_refresh_list: forceRefreshList };
                this.$emit("close-form", res_params);
                this.loading = false;

                // Notification
                const item = res.data["data"] || res.data;
                const event = this.method === "create" ? this.notifies.created : this.notifies.updated;
                this.$q.notify(this.notifies.entry_number + item["id"] + this.notifies.is_success + event);

                this.refreshList(res.data);
              });
            }
          })
          .catch(error => {
            this.$nextTick(() => {
              let res_params = {};
              res_params["method"] = this.method;
              this.loading = false;

              const password_error = error.response.data.errors.password;
              const cost_item_error = error.response.data.errors.cost_item;
              const snils_error = error.response.data.errors.snils;
              const contractor_company_ids_error = error.response.data.errors.contractor_company_ids;
              const contract_started_at_error = error.response.data.errors.contract_started_at;
              const contract_finished_at_error = error.response.data.errors.contract_finished_at;

              const customErrorShown = this.showErrorMessage([
                password_error,
                cost_item_error,
                snils_error,
                contractor_company_ids_error,
                contract_started_at_error,
                contract_finished_at_error,
              ]);

              if (!customErrorShown) {
                this.$q.notify(this.notifies.error_ask_admin);
              }

              if (error.response && password_error) {
                this.reLogin(error.response.status);
              }

              if (!customErrorShown) {
                this.$emit("close-form", res_params);
              }
            });
            console.log(error);
          });
      } else {
        this.$q.notify(this.notifies.fix_invalid_fields);
      }
    },

    refreshList(responseData) {
      const refresh = responseData.force_refresh_list === true;

      if (refresh) {
        this.$root.$emit("filters-form-reset");
      }
    },

    showErrorMessage(errorsResponses) {
      const list = errorsResponses.filter(item => item !== undefined && item !== null);
      let errorShown = false;

      list.forEach(errors => {
        const error = errors[0];

        this.$q.notify(error);

        if (!errorShown) {
          errorShown = true;
        }
      });

      return errorShown;
    },

    // checkFormValidation(form) {
    //   let invalid_form = Object.keys(form).reduce((result, e) => {
    //     if (form[e]['invalid'] === true) result[e] = form[e]
    //     return result;
    //   }, {})
    //
    //   let invalid_fields = Object.keys(invalid_form)
    //
    //   if (invalid_fields.length > 0) {
    //     this.form_valid = false
    //
    //     this.$store.commit('createFormField', {grid_name: this.grid, field: 'invalid_fields'})
    //
    //     let val = {}
    //     invalid_fields.forEach(f => val[f] = true)
    //     this.$store.commit('updateFormField', {grid_name: this.grid, field: 'invalid_fields', value: val})
    //
    //   } else {
    //     this.form_valid = true
    //     this.$store.commit('resetFormField', {grid_name: this.grid, field: 'invalid_fields'})
    //   }
    // },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.data.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },

  channels: {},
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/new-edit-form";
</style>
