<template lang="pug">
div
  .justify-center(v-if="dataHasLoaded")
    handle-form(
      @close-form="closeForm",
      :parentData="{ method: 'create', item: 'division', form_title: form_data.form_header.title.add, title_icon_path: title_icon_path, path: path['division'], grid: 'divisions', data: form_data }"
    )
</template>

<script>
import handleForm from "../../shared/forms/Form";

export default {
  components: {
    handleForm,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.$store.state.paths,
      title_icon_path: "add_header.svg",
      callback_params: this.parentData.callback_params,
      form_data: [],
      dataHasLoaded: false,
    };
  },

  created() {
    this.loadFormData();
  },

  methods: {
    closeForm(data) {
      this.$emit("close-form", data);
    },

    loadFormData() {
      let form_data_path = this.path["division"] + "/form_data";

      this.$backend
        .index(form_data_path, { params: {} })
        .then(({ data }) => {
          this.form_data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>
