<template lang="pug">
.equipment-show-card
  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading")
    q-card-section
      component(
        ref="actions",
        v-if="show_data['actions']",
        :is="getShowComponent('actions')",
        @refresh-table="refreshTable",
        @open-form="openForm",
        :parentData="{ row: row, grid: grid, grid_key: grid_key, path: path, data: show_data['actions'] }"
      )

    q-card-section
      component(
        ref="blocks",
        v-if="show_data['blocks']",
        :is="getShowComponent('blocks')",
        :parentData="{ row: row, show_details: false, data: show_data['blocks'] }"
      )
</template>

<script>
import showComponents from "../../../../../shared/show_card_components/index";

export default {
  components: {
    showComponents,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      row_id: this.parentData.row_id,
      tab_name: this.parentData.tab_name,
      row: {},
      show_data: [],
      loading: true,
    };
  },

  created() {
    this.loadShowData();
  },

  methods: {
    loadShowData(params = {}) {
      this.$backend
        .show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.row = data["common"]["data"];
          this.show_data = data["common"]["tabs"].find(tab => tab.name === this.tab_name)["data"];
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refreshTable(data) {
      this.$emit("refresh-table", data);
    },

    refreshRow(data) {
      this.row = data.attr.data || data.attr;
      // Object.keys(showComponents).forEach(component => {
      //   if (this.$refs[component]) {
      //     try {
      //       this.$refs[component].refreshRow(this.row)
      //     } catch(e) {}
      //   }
      // })
    },

    openForm(data) {
      this.$emit("open-form", data);
    },
  },
};
</script>

<style lang="scss">
.equipment-show-card {
  min-height: 70vh;

  .spinner-container.form-spinner {
    height: 70vh;
  }

  .remain-block {
    margin-top: 50px !important;
  }
}
</style>
