<template lang="pug">
.employee-statistics
  q-card(v-show="showRow(table_row, index)", v-for="(table_row, index) in data.fields", :key="index")
    .row.employee-statistics-row(v-for="row in data.scheme")
      div(
        v-show="showField(field, index)",
        v-for="field in row",
        :key="field.name",
        :class="getFieldClass(field)",
        :style="field.style || {}"
      )
        component(
          :is="getFieldComponent(getFieldDataByName(field, index))",
          :parentData="{ method: 'create', grid: grid, data: [getFieldDataByName(field, index)] }"
        )
    .destroy-button.button-group(style="padding: 5px")
      q-btn.table-button(flat, no-caps, label="Удалить", @click="deleteItem(index)")
  .col-1.button-group(style="padding: 5px")
    q-btn.table-button(flat, no-caps, label="Добавить", @click="pushItem()")
</template>

<script>
import fields from "../fields";

export default {
  components: {
    fields,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      data: this.parentData.data,
      grid: this.parentData.grid,
    };
  },
  beforeMount() {},

  methods: {
    pushItem() {
      let ar = this.data.fields.slice();
      let idx = ar.length - 1;
      let newline = ar[idx].map(el => {
        return Object.assign({}, el, { name: el.name.replace(/[0-9]/g, "") + ar.length });
      });
      newline.find(f => f.name === "_destroy" + ar.length)["value"] = null;
      newline.find(f => f.name === "id" + ar.length)["value"] = null;
      ar.push(newline);
      this.$set(this.data, "fields", ar);
    },

    deleteItem(index) {
      this.data.fields[index].find(f => f.name === "_destroy" + index)["value"] = "1";
      this.$set(this.data, "fields", this.data.fields);
      this.currentForm["_destroy" + index]["field"] = "1";
    },

    getFieldComponent(key) {
      if (key) {
        return fields[key.type];
      }
    },
    getFieldDataByName(field, row) {
      let field_data = Object.assign(
        {},
        this.data.fields[row].find(f => f.name === field.name + row),
      );
      // if (row !== 0) {
      //     delete field_data.label
      //     }
      return field_data;
    },
    getFieldClass(field) {
      let field_class = field.name;
      if (field.name === "id" || field.name === "_destroy") {
        field_class += " hidden";
      }
      return field_class;
    },
    showField(field, index) {
      if (field.name === "surname" || field.name === "first_name" || field.name === "middle_name") {
        this.checkField(field, index);
        if (this.currentForm["new_employee" + index]) {
          return this.currentForm["new_employee" + index].field;
        } else {
          return false;
        }
      }
      if (field.name === "employee_id") {
        this.checkField(field, index);
        if (this.currentForm["new_employee" + index]) {
          return !this.currentForm["new_employee" + index].field;
        } else {
          return true;
        }
      }
      return true;
    },
    checkField(field, index) {
      if (this.currentForm[field.name + index]) {
        if (field.name === "employee_id") {
          this.currentForm[field.name + index]["invalid"] =
            !this.currentForm[field.name + index]["field"] && !this.currentForm["new_employee" + index]["field"];
        }
        if (field.name === "surname" || field.name === "first_name" || field.name === "middle_name") {
          this.currentForm[field.name + index]["invalid"] =
            !this.currentForm[field.name + index]["field"] && this.currentForm["new_employee" + index]["field"];
        }
      }
    },
    showRow(row, index) {
      return this.data.fields[index].find(f => f.name === "_destroy" + index)["value"] !== "1";
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/employee_statistics";
</style>
