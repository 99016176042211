import { render, staticRenderFns } from "./covid_test.vue?vue&type=template&id=12fb94a3&lang=pug&"
import script from "./covid_test.vue?vue&type=script&lang=js&"
export * from "./covid_test.vue?vue&type=script&lang=js&"
import style0 from "./covid_test.vue?vue&type=style&index=0&id=12fb94a3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports