<template lang="pug">
div
  .issue-new-form.justify-center(v-if="dataHasLoaded")
    handle-form(
      @close-form="closeForm",
      :parentData="{ method: 'create', item: 'issue', callback_params: callback_params, form_title: form_data.form_header.title.add, title_icon_path: title_icon_path, path: path, grid: 'marketing_issues', data: form_data }"
    )
</template>

<script>
import handleForm from "../../../shared/forms/Form";

export default {
  components: {
    handleForm,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.$store.state.paths["marketing_issue"],
      title_icon_path: "add_header.svg",
      form_data: [],
      callback_params: this.parentData.callback_params,
      dataHasLoaded: false,
    };
  },

  created() {
    this.loadFormData();
  },

  mounted() {},

  beforeMount() {},

  methods: {
    closeForm(data) {
      this.$emit("close-form", data);
    },

    loadFormData() {
      let form_data_path = this.path + "/form_data";

      this.$backend
        .index(form_data_path, { params: {} })
        .then(({ data }) => {
          this.form_data = data;

          let callback_keys = Object.keys(this.callback_params);

          if (callback_keys.length > 0) {
            callback_keys.forEach(key => {
              this.form_data.fields.forEach(field => {
                if (field["name"] === key) {
                  field["value"] = this.callback_params[key];
                }
              });
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
