<template lang="pug">
#form.new-edit-form
  q-card.form-card
    q-card-section
      form-header(
        ref="form-header",
        @close-form="closeForm",
        :parentData="{ grid: grid, title: form_title, title_icon: title_icon_path }"
      )

    .spinner-container(v-if="loading")
      q-spinner.form-spinner(color="primary", size="3em")

    q-card-section(v-show="!loading")
      q-form(@submit="submitForm", @reset="resetForm")
        .row(v-for="(row, i) in data.scheme")
          div(v-for="field in row", :key="field.name", :class="'col-' + field.col", style="padding: 5px")
            component(
              v-show="getFieldDataByName(field)",
              :is="getFieldComponent(getFieldDataByName(field))",
              @show-field="showField",
              @fields-loaded="fieldsLoaded",
              :parentData="{ method: method, method_limit: getFieldDataByName(field).method_limit, grid: grid, data: [getFieldDataByName(field)] }"
            )

        component(
          :is="getTableComponent()",
          :key="tableKey",
          :parentData="{ method: method, grid: grid, data: table_data }"
        )

        .button-group.row.justify-center
          q-btn.new-edit-form-submit(flat, no-caps, label="Сохранить", type="submit")
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import fields from "./fields";
import tables from "./tables";

export default {
  components: {
    formHeader,
    fields,
    tables,
  },
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      method: this.parentData.method,
      item: this.parentData.item,
      item_id: this.parentData.item_id || "",
      callback_params: this.parentData.callback_params,
      path: this.parentData.path,
      data: this.parentData.data,
      table_data: this.parentData.table_data,
      grid: this.parentData.grid,
      form_title: this.parentData.form_title,
      title_icon_path: this.parentData.title_icon_path,
      loading_fields: [],
      loading: true,
      tableKey: 0,
      form_valid: false,
    };
  },

  computed: {
    district() {
      return this.currentForm.district;
    },
    boss() {
      return this.currentForm.user_id;
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
    "district.field.value": function (newval, oldValue) {
      if (newval && oldValue && newval !== oldValue) {
        this.loadTableData(newval, this.boss.field.value);
      }
    },
    "boss.field.value": function (newval, oldValue) {
      if (newval && oldValue && newval !== oldValue) {
        let district = null;
        if (this.district) {
          district = this.district.field.value;
        }
        this.loadTableData(district, newval);
      }
    },
  },

  created() {
    this.resetForm();
    this.fieldsLoaded();
  },

  mounted() {},

  methods: {
    showField(data) {
      this.$nextTick(() => {
        if (this.$refs[data.name]) {
          if (data.val) {
            this.$refs[data.name][0].style.display = "flex";
          } else {
            this.$refs[data.name][0].style.display = "none";
          }
        }
      });
    },

    closeForm(params = {}) {
      params["method"] = this.method;
      this.$emit("close-form", params);
    },

    submitForm() {
      let params = {};
      let form = this.currentForm;
      this.checkFormValidation(form);

      if (this.form_valid) {
        this.loading = true;

        let result = Object.keys(form)
          .filter(f => /\d+$/.test(f) === false)
          .reduce((result, e) => {
            if (Array.isArray(form[e]["field"]) && form[e]["selected_items"]) {
              result[e] = form[e]["selected_items"];
            } else if (Array.isArray(form[e]["field"])) {
              result[e] = form[e]["field"].map(e => e["value"]);
            } else if (typeof form[e]["field"] === "object") {
              result[e] = form[e]["field"]["value"];
            } else {
              result[e] = form[e]["field"];
            }
            return result;
          }, {});

        let nested_attributes = [];
        this.table_data.fields.forEach(row => {
          nested_attributes.push({});
          row.forEach(f => {
            if (typeof form[f.name]["field"] === "object") {
              nested_attributes[nested_attributes.length - 1][f.name.replace(/[0-9]/g, "")] =
                form[f.name]["field"]["value"];
            } else {
              nested_attributes[nested_attributes.length - 1][f.name.replace(/[0-9]/g, "")] = form[f.name]["field"];
            }
          });
        });

        result[this.getNestedTableName() + "_attributes"] = nested_attributes;

        params[this.item] = result;

        // console.log(params)

        this.$backend[this.method](this.path, this.item_id, params)
          .then(res => {
            // console.log(res.data)

            if (res.data) {
              this.$nextTick(() => {
                this.resetForm();
                let res_params = {};
                res_params["data"] = res.data;
                res_params["method"] = this.method;
                this.$emit("close-form", res_params);
                this.loading = false;

                let event = this.method === "create" ? this.notifies.created : this.notifies.updated;
                this.$q.notify(this.notifies.success_entry + event);
              });
            }
          })
          .catch(error => {
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.$nextTick(() => {
              let res_params = {};
              res_params["method"] = this.method;
              this.$emit("close-form", res_params);
              this.loading = false;

              this.$q.notify(this.notifies.error_ask_admin);
            });
            console.log(error);
          })
          .finally(() => {});
      } else {
        this.$q.notify(this.notifies.fix_invalid_fields);
      }
    },

    // checkFormValidation(form) {
    //   let invalid_form = Object.keys(form).reduce((result, e) => {
    //     if (form[e]['invalid'] === true) result[e] = form[e]
    //     return result;
    //   }, {})
    //
    //   let invalid_fields = Object.keys(invalid_form)
    //
    //   if (invalid_fields.length > 0) {
    //     this.form_valid = false
    //
    //     this.$store.commit('createFormField', {grid_name: this.grid, field: 'invalid_fields'})
    //
    //     let val = {}
    //     invalid_fields.forEach(f => val[f] = true)
    //     this.$store.commit('updateFormField', {grid_name: this.grid, field: 'invalid_fields', value: val})
    //
    //   } else {
    //     this.form_valid = true
    //     this.$store.commit('resetFormField', {grid_name: this.grid, field: 'invalid_fields'})
    //   }
    // },

    resetForm() {
      let fields = Object.keys(this.currentForm);
      this.$store.commit("resetFormAllFields", { grid_name: this.grid, fields: fields });
    },

    getFieldComponent(key) {
      if (key) {
        return fields[key.type];
      }
    },

    getFieldDataByName(field) {
      return this.data.fields.find(f => f.name === field.name);
    },

    getNestedTableName() {
      let owner_child = {
        illness_status: "illness_statistics",
        employee_status: "employee_statistics",
        covid_test: "employee_covid_tests",
      };
      return owner_child[this.item];
    },

    getTableComponent() {
      return tables[this.getNestedTableName()];
    },

    loadTableData(district, user) {
      let form_data_path = this.path + "/collection";
      this.$backend
        .collection(form_data_path, { district: district, user: user })
        .then(({ data }) => {
          this.table_data = data;
          this.tableKey += 1;
          // this.$set(this.data, 'fields', ar)
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.data.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },

  channels: {},
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/new-edit-form";
</style>
