<template lang="pug">
div
  div(v-if="state")
    .spinner-container.modal-spinner(v-if="!dataHasLoaded")
      q-spinner(color="primary", size="3em")

    div(v-if="dataHasLoaded")
      .checklist-form-button
        q-btn(flat, no-caps, @click="openForm()", :class="btn_class")
          template(slot="default")
            inline-svg.checklist-form-button-icon(:src="require(`../../../assets/icons/checklist/btn_icon.svg`)")

          template(slot="default")
            span.checklist-form-button-label {{ label }}

      q-dialog(v-model="modal.form")
        checklist-form(
          ref="checklist-form",
          @close-form="closeForm",
          @reload-form-data="loadFormData",
          :parentData="{ row: row, data: form_data, path: path, grid: grid, header_title: header_title }"
        )

      q-dialog(v-model="modal.logs")
        checklist-logs(
          ref="checklist-logs",
          @close-form="closeForm",
          :parentData="{ row: row, path: path, grid: grid, header_title: header_title }"
        )
</template>

<script>
import checklistForm from "./form";
import checklistLogs from "./logs";

export default {
  components: {
    checklistForm,
    checklistLogs,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      grid: "checklists",
      path: this.$store.state.paths["checklist"],
      form_data: [],

      header_title: "Чеклист",

      dataHasLoaded: false,

      modal: {
        form: false,
        logs: false,
      },

      params_by_state: {
        begin: {
          modal: "form",
          label: "Начать чеклист",
          btn_class: "",
        },
        continue: {
          modal: "form",
          label: "Продолжить чеклист",
          btn_class: "continue-btn",
        },
        show: {
          modal: "logs",
          label: "Посмотреть чеклист",
          btn_class: "show-btn",
        },
      },
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    state() {
      let el = this.row["checklist_el"];
      let logs = this.row["checklist_logs"];

      if (!el && logs.length === 0) {
        return "begin";
      } else if (el && logs.length > 0) {
        return "continue";
      } else if (!el && logs.length > 0) {
        return "show";
      } else {
        return undefined;
      }
    },

    btn_class() {
      return this.params_by_state[this.state].btn_class;
    },

    label() {
      return this.params_by_state[this.state].label;
    },
  },

  created() {
    this.$store.commit("initialGridsState", { grid: this.grid, attr: {} });
    this.loadFormData();
  },

  beforeDestroy() {},

  methods: {
    openForm() {
      this.resetForm();
      this.modal[this.params_by_state[this.state]["modal"]] = true;
    },

    closeForm() {
      this.modal[this.params_by_state[this.state]["modal"]] = false;
    },

    loadFormData(next_el = undefined) {
      let path = this.path + "/form_data";
      let params = {
        issue_id: this.row.id,
        checklist_name: this.row.checklist_name,
        checklist_el: next_el || this.row.checklist_el,
      };

      this.$backend
        .index(path, { params: params })
        .then(({ data }) => {
          this.form_data = data;

          if (next_el) {
            data.fields.forEach(field => {
              this.$store.commit("createFormField", { grid_name: this.grid, field: field.name });
              this.$refs["checklist-form"].setFieldVal({ field: field.name, val: field.value });
            });
            this.$refs["checklist-form"].setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/checklists/main";
</style>
