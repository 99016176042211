<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: header_title, title_icon: 'checklist.svg' }"
    )

  .spinner-container.modal-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  q-card-section(v-show="!loading")
    .checklist-element-title
      span {{ title }}

    q-form.checklist-element-form
      component(
        :ref="field.name",
        v-for="field in fields",
        :key="field.name",
        :is="getFieldComponent(field.type)",
        @fields-loaded="fieldsLoaded",
        :parentData="{ grid: grid, data: getFieldDataByName(field) }"
      )

  q-card-actions.button-group.row.justify-center(v-show="!loading")
    q-btn.modal-form-submit(flat, no-caps, label="Продолжить", @click="onSubmit()")
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import fields from "../../shared/forms/fields";

export default {
  components: {
    formHeader,
    fields,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,

      form_valid: false,

      loading_fields: [],
      loading: true,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    data() {
      return this.parentData.data;
    },

    fields() {
      return this.data.fields;
    },

    title() {
      return this.data.title;
    },

    header_title() {
      return this.parentData.header_title;
    },

    // buttons() {
    //   return this.parentData.data.header.filters_form.form_buttons
    // }
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.fieldsLoaded();
  },

  methods: {
    setFieldVal(attr) {
      this.$nextTick(() => {
        if (this.$refs[attr.field]) {
          this.$refs[attr.field][0].setField(attr.val);
        }
      });
    },

    setLoading(val) {
      this.loading = val;
    },

    closeForm() {
      this.$emit("close-form");
    },

    onSubmit(params = {}) {
      let form = this.currentForm;

      this.checkFormValidation(form);

      if (this.form_valid) {
        this.loading = true;

        let result = Object.keys(form).reduce((result, e) => {
          if (Array.isArray(form[e]["field"]) && form[e]["selected_items"]) {
            result[e] = form[e]["selected_items"];
          } else if (Array.isArray(form[e]["field"])) {
            result[e] = form[e]["field"].map(e => e["value"]);
          } else if (typeof form[e]["field"] === "object") {
            result[e] = form[e]["field"]["value"];
          } else {
            result[e] = form[e]["field"];
          }
          return result;
        }, {});

        params = {
          issue_id: this.row.id,
          checklist_name: this.row.checklist_name,
          checklist_el: this.data.name,
          form: result,
        };

        this.$backend
          .create(this.path + "/form_submit", null, params)
          .then(({ data }) => {
            this.$root.$emit("load-show-data", this.row);
            let next_el = data.next_el;
            if (next_el) {
              this.resetForm();
              this.$emit("reload-form-data", next_el);
            } else {
              this.$emit("close-form");
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.loading = false;
            this.$q.notify(this.notifies.error_ask_admin);
          });
      } else {
        this.$q.notify(this.notifies.fix_invalid_fields);
      }
    },

    getFieldComponent(key) {
      return fields[key];
    },

    getFieldDataByName(field) {
      return this.fields.filter(f => f.name === field.name);
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/checklists/form";
</style>
