<template lang="pug">
.additional-tarifiers-list-wrapper
  table.additional-tarifiers-list(cellspacing="0", cellpadding="0")
    thead.additional-tarifiers-list-thead
      tr
        th(v-for="column in additional_columns", :class="`list-item ${handleColumn(column)}`") {{ issue_tarifier_locales[`additional_${column.replace(/\./, "_")}`] }}

    tbody.additional-tarifiers-list-tbody
      tr(v-for="(row, i) in collection")
        td(v-for="column in additional_columns", :class="`list-item ${handleColumn(column)}`")
          span(v-if="boolean_columns.includes(handleColumn(column))")
            q-icon.boolean-columns-success-icon(:name="row.work[handleColumn(column)] ? 'done' : 'close'")

          span(v-else-if="column.match('work.')") {{ row.work[handleColumn(column)] }}
          span(v-else-if="column.match('material.')") {{ row.material[handleColumn(column)] }}

          span(v-else-if="column === 'delete'")
            q-icon.delete-additional-tarifiers-icon(
              name="cancel",
              @click.stop="onDelete(row)",
              :title="issue_tarifier_locales.additional_delete"
            )

          span(v-else) {{ row[handleColumn(column)] }}
</template>

<script>
export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      boolean_columns: ["cramped_work", "night_work"],
    };
  },

  computed: {
    additional_columns() {
      return this.parentData.columns;
    },

    collection() {
      return this.parentData.collection;
    },

    issue_tarifier_locales() {
      return this.locales.issue_tarifier[this.current_locale];
    },
  },

  methods: {
    onDelete(item) {
      this.$emit("reload-additional", { action: "delete", attr: item });
    },

    handleColumn(column) {
      return column.replace(/^([^.]+)\./, "");
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/main";

.additional-tarifiers-list-wrapper {
  overflow-x: auto;
  margin: 10px;
}

.additional-tarifiers-list-wrapper::-webkit-scrollbar-track {
  margin-left: 5px;
  margin-right: 5px;
}

.additional-tarifiers-list {
  width: 98%;
  color: var(--selected-items-form-color);
  margin-top: 10px;
  margin-bottom: 20px;

  .additional-tarifiers-list-thead {
    width: 100%;

    .list-item {
      min-width: 70px;
      text-align: left;

      /*height: 40px;*/
      padding: 10px;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }

    .list-item.title {
      min-width: 150px;
    }
  }

  .additional-tarifiers-list-tbody {
    width: 100%;
    padding-bottom: 10px;

    tr:hover {
      td {
        background-color: #d3d3d31c;
      }
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .list-item {
      height: 40px;
      padding: 10px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }

    .list-item.cramped_work,
    .list-item.night_work,
    .list-item.delete {
      text-align: center;
    }

    .list-item.delete {
      margin-top: 9px;

      .delete-additional-tarifiers-icon {
        cursor: pointer;

        color: var(--field-cancel-icon-color);
      }
    }
  }
}
</style>
