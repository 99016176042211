<template lang="pug">
q-input.tech-map-textarea(
  filled,
  type="textarea",
  :value="value",
  :placeholder="value || '-'",
  :label-slot="false",
  @input="onChange($event)"
)
</template>

<script>
export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      index: this.parentData.index,
      value_key: this.parentData.value_key,

      value_key_parent: this.parentData.value_key_parent,
      sub_index: this.parentData.sub_index,

      tech_map_key: this.parentData.tech_map_key,
    };
  },

  computed: {
    value() {
      return this.parentData.value;
    },
  },

  watch: {
    value(newVal, oldVal) {},
  },

  created() {},

  beforeMount() {},

  methods: {
    onChange(val) {
      let attr = {
        index: this.index,
        value_key: this.value_key,
        value: val,
      };

      if (this.value_key_parent) {
        attr["value_key_parent"] = this.value_key_parent;
        attr["sub_index"] = this.sub_index;
      }

      this.$store.commit("updateEquipmentTechMap", {
        grid_name: this.grid,
        tech_map_key: this.tech_map_key,
        attr: attr,
      });
    },
  },
};
</script>

<style lang="scss">
.tech-map-textarea {
  height: 25px;

  .q-field__control {
    padding: 0;
    background: transparent;
    height: fit-content;

    .q-field__control-container {
      textarea {
        padding: 0;
        overflow-y: hidden;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #5b7aff;
      }
    }
  }
}
</style>
