<template lang="pug">
div
  .tarifier-edit-form.justify-center(v-if="dataHasLoaded")
    handle-form(
      @close-form="closeForm",
      :parentData="{ method: 'update', item: 'tarifier', item_id: row.id, title_icon_path: title_icon_path, form_title: form_data.form_header.title.edit + ' №' + row.id, path: path['tarifier'], grid: 'tarifiers', data: form_data }"
    )
</template>

<script>
import handleForm from "../../shared/forms/Form";

export default {
  components: {
    handleForm,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.$store.state.paths,
      title_icon_path: "edit_header.svg",
      row: this.parentData.row,
      callback_params: this.parentData.callback_params,
      form_data: [],
      dataHasLoaded: false,
    };
  },

  created() {
    this.loadFormData();
    this.$emit("row-is-active", true);
  },

  mounted() {},

  beforeMount() {},

  methods: {
    closeForm(data) {
      this.$emit("close-form", data);
    },

    loadFormData() {
      let form_data_path = this.path["tarifier"] + "/form_data";

      let params = {};
      if (this.row) {
        params["id"] = this.row.id;
      }

      this.$backend
        .index(form_data_path, { params: params })
        .then(({ data }) => {
          this.form_data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
