<template lang="pug">
div
  .spinner-container.modal-spinner(v-if="!dataHasLoaded")
    q-spinner(color="primary", size="3em")

  div(v-if="dataHasLoaded")
    .issue-tarifier-form-button
      q-btn.tarifier-btn(flat, no-caps, @click="openForm()")
        template(slot="default")
          inline-svg.issue-tarifier-form-button-icon(:src="require(`../../../assets/icons/checklist/btn_icon.svg`)")

        template(slot="default")
          span.issue-tarifier-form-button-label {{ form_button_lable }}

    q-dialog(v-model="modal.form", @hide="closeForm")
      issue-tarifier-form(
        ref="issue-tarifier-form",
        @close-form="closeForm",
        :parentData="{ row: row, data: form_data, path: path, grid: grid, header_title: issue_tarifier_locales.form_header }"
      )
</template>

<script>
import issueTarifierForm from "./form";

export default {
  components: {
    issueTarifierForm,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      grid: "issue_tarifiers",
      path: this.$store.state.paths["issue_tarifier"],
      form_data: [],

      dataHasLoaded: false,

      modal: {
        form: false,
      },
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    issue_tarifier_locales() {
      return this.locales.issue_tarifier[this.current_locale];
    },

    form_button_lable() {
      if (this.row.has_estimate) {
        return this.issue_tarifier_locales.edit_estimate;
      } else {
        return this.issue_tarifier_locales.add_estimate;
      }
    },
  },

  created() {
    this.$store.commit("initialGridsState", { grid: this.grid, attr: {} });
    this.loadFormData();
  },

  beforeDestroy() {},

  methods: {
    openForm() {
      this.loadFormData();
      setTimeout(() => {
        this.modal.form = true;
      }, 300);
    },

    closeForm() {
      this.resetForm();
      this.modal.form = false;
    },

    loadFormData() {
      let path = this.path + "/form_data";
      let params = {
        issue_id: this.row.id,
      };

      this.$backend
        .index(path, { params: params })
        .then(({ data }) => {
          this.form_data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },

    resetForm() {
      let fields = Object.keys(this.currentForm);
      this.$store.commit("resetFormAllFields", { grid_name: this.grid, fields: fields });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/issue_tarifier/main";
</style>
