<template lang="pug">
.editable-buttons-group
  .button-wrapper(v-for="button in buttons", :style="button.style")
    q-btn(
      flat,
      no-caps,
      :class="button.name + '-button'",
      @click="onClickFn(button)",
      :disabled="button.checkable && !checkable"
    )
      template(slot="default")
        inline-svg(:src="require(`../../../../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`)")
      template(slot="default")
        span.action-label {{ button.label }}
</template>

<script>
export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      buttons: this.parentData.buttons,
    };
  },

  computed: {
    checkable() {
      return this.parentData.checkable;
    },
  },

  watch: {},

  created() {},

  beforeMount() {},

  methods: {
    onClickFn(button) {
      this.$emit("on-click-fn", button);
    },
  },
};
</script>

<style lang="scss"></style>
