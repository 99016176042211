<template lang="pug">
q-card#form.modal-form-card(style="width: 800px !important")
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: header_title, title_icon: 'checklist.svg' }"
    )

  .spinner-container.modal-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  q-card-section(v-show="!loading")
    q-form
      component.issue-tarifier-form-field(
        :ref="field.name",
        v-for="field in fields",
        :key="field.name",
        :is="getFieldComponent(field.type)",
        @show-field="showField",
        @fields-loaded="fieldsLoaded",
        :parentData="{ grid: grid, data: getFieldDataByName(field) }"
      )

    .show-additional-checkbox
      .form-checkbox
        q-checkbox(dark, v-model="show_additional", :label="issue_tarifier_locales.add_additional")

    .form-field-label(v-if="show_additional")
      span Дополнительные тарифы

    .additional-tarifiers(v-if="show_additional")
      additional-tarifiers-form(
        ref="additional-tarifiers-form",
        @set-form-valid="setFormValid",
        @reload-additional="reloadAdditional"
      )

      additional-tarifiers-list(
        v-show="additionals.length > 0",
        @reload-additional="reloadAdditional",
        :parentData="{ collection: additionals, columns: additional_columns }"
      )

  q-card-actions.button-group.row.justify-center(v-show="!loading")
    q-btn.modal-form-submit(flat, no-caps, :label="issue_tarifier_locales.form_submit", @click="onSubmit()")
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import fields from "../../shared/forms/fields";
import additionalTarifiersForm from "./additional_tarifiers/form";
import additionalTarifiersList from "./additional_tarifiers/list";

export default {
  components: {
    formHeader,
    fields,
    additionalTarifiersForm,
    additionalTarifiersList,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.$store.state.paths["issue"],
      grid: this.parentData.grid,

      form_valid: false,
      additional_form_valid: true,

      loading_fields: [],

      show_additional: false,
      additionals: [],
      additional_columns: [],

      loading: true,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    data() {
      return this.parentData.data;
    },

    fields() {
      return this.data.fields;
    },

    title() {
      return this.data.title;
    },

    header_title() {
      return this.parentData.header_title;
    },

    issue_tarifier_locales() {
      return this.locales.issue_tarifier[this.current_locale];
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },

    show_additional(newVal, oldVal) {
      if (newVal === false) {
        this.additionals = [];
        this.$refs["additional-tarifiers-form"].resetForm();
      }
    },
  },

  created() {
    this.loadAdditionalColumns();
    this.loadAdditionals();
    this.fieldsLoaded();
  },

  methods: {
    reloadAdditional(data) {
      if (data.action === "add") {
        data.attr["index"] = this.additionals.length;
        this.additionals = this.additionals.concat(data.attr);
      } else if (data.action === "delete") {
        this.additionals = this.additionals.filter(e => {
          return e["index"] !== data.attr["index"];
        });
      }
    },

    showField(data) {
      this.$nextTick(() => {
        if (this.$refs[data.name][0]) {
          if (data.val) {
            this.$refs[data.name][0].$el.style.display = "block";
          } else {
            this.$refs[data.name][0].$el.style.display = "none";
          }
        }
      });
    },

    setFieldVal(attr) {
      this.$nextTick(() => {
        if (this.$refs[attr.field]) {
          this.$refs[attr.field][0].setField(attr.val);
        }
      });
    },

    setLoading(val) {
      setTimeout(() => {
        this.loading = val;
      }, 1000);
    },

    closeForm() {
      this.$emit("close-form");
    },

    setFormValid(val) {
      this.additional_form_valid = val;
    },

    onSubmit(params = {}) {
      let form = this.currentForm;

      this.checkFormValidation(form);

      let additional_form = this.$refs["additional-tarifiers-form"];
      if (additional_form) {
        this.additional_form_valid = false;
        additional_form.checkAdditionalsForm();
      } else {
        this.additional_form_valid = true;
      }

      if (this.form_valid && this.additional_form_valid) {
        this.loading = true;

        let result = Object.keys(form).reduce((result, e) => {
          if (Array.isArray(form[e]["field"]) && form[e]["selected_items"]) {
            result[e] = form[e]["selected_items"];
          } else if (Array.isArray(form[e]["field"])) {
            result[e] = form[e]["field"].map(e => e["value"]);
          } else if (typeof form[e]["field"] === "object") {
            result[e] = form[e]["field"]["value"];
          } else {
            result[e] = form[e]["field"];
          }
          return result;
        }, {});

        params["issue"] = result;
        params["issue"]["additional_tarifiers"] = this.additionals;

        this.$backend
          .update(this.path, this.row.id, params)
          .then(({ data }) => {
            this.$root.$emit("load-show-data", this.row);
            this.$emit("close-form");
            this.$root.$emit("reload-show-tab", "issue_tarifiers");

            let res_params = {};
            res_params["attr"] = data;
            res_params["action"] = "update";
            this.$root.$emit("reload-common-issues-grid", res_params);
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.loading = false;
            this.$q.notify(this.notifies.error_ask_admin);
          });
      } else {
        this.$q.notify(this.notifies.fix_invalid_fields);
      }
    },

    getFieldComponent(key) {
      return fields[key];
    },

    getFieldDataByName(field) {
      return this.fields.filter(f => f.name === field.name);
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.setLoading(false);
      }
    },

    loadAdditionalColumns(params = {}) {
      this.$backend
        .index(`${this.$store.state.paths["additional_tarifier"]}/columns`, { params: params })
        .then(({ data }) => {
          this.additional_columns = data.columns;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        });
    },

    loadAdditionals(params = {}) {
      params["issue_id"] = this.row.id;

      this.$backend
        .collection(`${this.$store.state.paths["additional_tarifier"]}/collection`, params)
        .then(({ data }) => {
          if (data.options.length > 0) {
            this.additionals = data.options;
            this.show_additional = true;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/forms/fields/checkbox";

.issue-tarifier-form-field {
  margin-bottom: 20px;
}

.additional-tarifiers {
  background: var(--show-card-emergency-block-background);
  border-radius: 5px;
  border: 1px solid #d3d3d3;

  .additional-tarifiers-title {
  }
}
</style>
